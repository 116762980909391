<template>
    <div class="page" v-loading="pageLoading">
        <div class="container">
            <div class="header">
                <div class="icon"></div>
                <div class="title">{{ $t('meetingPage.title') }}</div>
                <div class="search">
                    <el-button class="button" type="primary" @click="handleCreate">创建会议</el-button>
                </div>
            </div>
            <div class="content">
                <div class="condition">
                    <!-- <el-radio-group  class="radio-group" style="" v-model="searchForm.meetingStatus">
            <el-radio-button label="1">进行中/即将召开</el-radio-button>
            <el-radio-button label="2">已结束的会议</el-radio-button>
          </el-radio-group> -->
                    <div class="tabs">
                        <div :class="{ 'tab-item': true, 'active': item.id == searchForm.meetingStatus, 'tab-disabled': listLoading }"
                            v-for="item in tabList" :key="'tab' + item.id" @click="handleTabSwitch(item)">
                            {{ item.name }}
                        </div>
                    </div>
                    <div>
                        <el-tooltip content="创建会议需要一定时间，请勿重复创建，点击按钮可刷新会议列表。" placement="left" effect="light">
                            <el-button type="primary" :icon="Warning" link />
                        </el-tooltip>
                        <el-button style="width:120px;margin-left:auto" class="button" type="primary"
                            @click="judgeListRequest">刷新会议列表</el-button>
                    </div>
                    <!-- <el-select style="width:180px;margin-left:auto" v-model="searchForm.userId" @change="userChange" placeholder="请选择用户" size="large">
            <el-option
              v-for="item in hostList"
              :key="item.userId"
              :label="item.username"
              :value="item.userId"
            >
            </el-option>
          </el-select> -->
                </div>
                <!-- <el-tabs class="status-tab" v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane label="进行中" name="first"></el-tab-pane>
          <el-tab-pane label="已取消" name="second"></el-tab-pane>
        </el-tabs> -->
                <div>
                    <el-table class="table" :data="tableData" v-loading="listLoading" header-cell-class-name="header"
                        style="width: 100%">
                        <el-table-column prop="date" label="序号" align="left" width="60">
                            <template v-slot="scope">
                                {{ scope.$index + 1 }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="subject" label="会议主题" align="left">
                        </el-table-column>
                        <!-- <el-table-column prop="meetingId" label="会议Id" align="left">
            </el-table-column> -->
                        <el-table-column prop="meetingCode" label="会议号" align="left">
                        </el-table-column>
                        <el-table-column prop="startTime" width="170" label="开始时间" align="left">
                            <template v-if="searchForm.meetingStatus == 3" v-slot="scope">
                                {{ moment(Number(scope.row.startTime)).format('YYYY-MM-DD HH:mm:ss') }}
                            </template>
                            <template v-else v-slot="scope">
                                {{ moment.unix(Number(scope.row.startTime)).format('YYYY-MM-DD HH:mm:ss') }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="endTime" width="170" label="结束时间" align="left">
                            <template v-if="searchForm.meetingStatus == 3" v-slot="scope">
                                {{ moment(Number(scope.row.endTime)).format('YYYY-MM-DD HH:mm:ss') }}
                            </template>
                            <template v-else v-slot="scope">
                                {{ moment.unix(Number(scope.row.endTime)).format('YYYY-MM-DD HH:mm:ss') }}
                            </template>
                        </el-table-column>
                        <!-- <el-table-column v-if="searchForm.meetingStatus == 1" prop="joinUrl" label="入会链接" align="left">
              <template v-slot="scope">
                <el-button style="margin-right:5px;" type="primary" link @click="handleCopy(scope.row.joinUrl)">复制链接</el-button>
                <span class="link-url" @click="handleOpen(scope.row.joinUrl)"> {{ scope.row.joinUrl }}</span>
              </template>
            </el-table-column> -->
                        <el-table-column label="操作" width="160" align="center">
                            <template v-slot="scope">
                                <el-button type="primary" link @click="handleDetail(scope.row)">详情</el-button>
                                <el-button type="primary" v-show="searchForm.meetingStatus == 1" link
                                    @click="handleEdit(scope.row)">编辑</el-button>
                                <el-button type="danger" v-show="searchForm.meetingStatus == 1" link
                                    @click="handleDel(scope.row)">取消</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <common-page v-model:current-page="pageObj.page" v-model:page-size="pageObj.size" :total="pageObj.total"
                        @size-change="handleSizeChange" @current-change="handleCurrentChange">
                    </common-page>
                </div>
            </div>
        </div>
        <!-- 创建和修改弹窗 -->
        <el-dialog ref="createDialog" class="create-dialog" :show-close="false" :title="createDialog.title"
            :close-on-click-modal=false v-model="createDialog.show" width="40vw" center>
            <div class="main">
                <el-form ref="createForm" label-position="right" label-width="120px" :model="createForm"
                    :rules="createRules" style="width:100%">
                    <el-form-item prop="subject" label="会议主题：">
                        <el-input v-model="createForm.subject" maxlength="20" placeholder="请输入会议主题"></el-input>
                    </el-form-item>
                    <el-form-item prop="userId" label="主持人：">
                        <el-select style="width:100%" v-model="createForm.userId" :disabled="createForm.isEdit"
                            placeholder="请选择主持人">
                            <el-option v-for="item in hostList" :key="item.userId" :label="item.username"
                                :value="item.userId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="dateRange" label="起始时间：">
                        <el-date-picker v-model="createForm.dateRange" type="datetimerange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" value-format="x" :teleported="false">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item prop="room" label="等候室：">
                        <el-switch v-model="createForm.room" active-color="#409eff" inactive-color="#ff4949"
                            active-text="开启" inactive-text="关闭" />
                    </el-form-item>
                    <el-form-item prop="guest" label="嘉宾列表：">
                        <!-- <el-select
              style="width:100%"
              v-model="createForm.guest"
              multiple
              collapse-tags
              placeholder="请选择嘉宾"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
                        <!-- <el-tree-select
              ref="selectTree"
              style="width:100%"
              v-model="createForm.guest"
              lazy
              show-checkbox
              multiple
              node-key="value"
              :load="loadTree"
              :props="props"
              :cache-data="cacheData"
              :collapse-tags="true"
              :collapse-tags-tooltip="true"
              @check="handleTreeCheck"
              @remove-tag="removeTreeTag"
              @node-click="handleNodeClick"
            /> -->
                        <div
                            v-if="(createForm.guest && createForm.guest.length > 0) || (userIdAll && userIdAll.length > 0)">
                            <el-button class="button" type="primary" @click="chooseGuest1">修改已选择嘉宾</el-button>
                        </div>
                        <div v-else>
                            <el-button class="button" type="primary" @click="chooseGuest2">请选择嘉宾</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div class="boxItem">
                            <!-- <div class="box11" v-for="item in guestData" :key="item">
                                <span>{{ item.name }};</span>
                            </div> -->
                            {{ guestName }}
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelCreate">取 消</el-button>
                    <el-button :loading="submitLoading" type="primary" @click="submitCreateForm">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog ref="guestDialog" class="guest-dialog" :show-close="false" title="选择嘉宾" :close-on-click-modal=false
            v-model="guestDialog.show" width="70vw" center v-if="destroyStatus">
            <div style="height:55vh;overflow-y:auto">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="部门" name="first">
                        <div class="formClass">
                            <el-form :model="depForm" class="formClass">
                                <el-form-item label="部门名称:" style="width:40%;margin-right:5px">
                                    <el-input v-model="depForm.name" />
                                </el-form-item>
                                <el-form-item label="部门编码:" style="width:40%;margin-right:5px">
                                    <el-input v-model="depForm.orgCode" />
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="queryDep">查询</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="treeClass">
                            <el-tree v-if="treeStatus" ref="selectTree" style="width:100%" v-model="createForm.guest" lazy
                                show-checkbox node-key="id" :load="loadTree" :props="props" :cache-data="cacheData"
                                :collapse-tags="true" :collapse-tags-tooltip="true" @check-change="handleTreeCheckChange"
                                :expand-on-click-node="false" @remove-tag="removeTreeTag" @node-click="handleNodeClick"
                                v-loading="treeLoading" @check="checkClick" :check-strictly="true">
                                <template v-slot="{ node, data }">
                                    <span class="spanClass"> {{ node.label }}</span>
                                    <span>
                                        <el-checkbox v-model="data.hasChild" @change="changeTree(data)"> 包含子节点</el-checkbox>
                                    </span>
                                </template>
                            </el-tree>
                            <el-tree v-else ref="selectTree1" style="width:100%" show-checkbox node-key="orgCode"
                                :props="treeProps" :data="treeData" :cache-data="cacheData" :collapse-tags="true"
                                :collapse-tags-tooltip="true" @remove-tag="removeTreeTag" @node-click="handleNodeClick"
                                v-loading="treeLoading" :expand-on-click-node="false" @check="checkClick1"
                                :check-strictly="true">
                                <template v-slot="{ node, data }">
                                    <span class="spanClass"> {{ node.label }}</span>
                                    <span>
                                        <el-checkbox v-model="data.hasChild" @change="changeTree1(data)">
                                            包含子节点</el-checkbox>
                                    </span>
                                </template>
                            </el-tree>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="人员" name="second">
                        <div>
                            <el-form :model="persForm" class="formClass">
                                <el-form-item label="姓名:" style="width:25%;margin-right:5px">
                                    <el-input v-model="persForm.name" />
                                </el-form-item>
                                <el-form-item label="员工编码:" style="width:29%;margin-right:5px">
                                    <el-input v-model="persForm.userId" />
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="queryUser">查询</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="boxClass">
                            <div class="tree">
                                <el-tree style="width:100%" lazy node-key="id" :load="loadTree1" :props="props"
                                    :cache-data="cacheData" :collapse-tags="true" :collapse-tags-tooltip="true"
                                    @remove-tag="removeTreeTag" @node-click="userHandleNodeClick" v-loading="treeLoading">
                                </el-tree>
                            </div>
                            <div class="table">
                                <el-table ref="tableRef" :data="tableData11" style="width: 100%" class="tableList"
                                    v-loading="tableLoading" @selection-change="handleSelectionChange" row-key="userId">
                                    <el-table-column type="selection" width="55">
                                    </el-table-column>
                                    <el-table-column prop="guestName" label="姓名">
                                    </el-table-column>
                                    <el-table-column prop="userId" label="员工编号">
                                    </el-table-column>
                                    <el-table-column prop="orgName" label="归属部门">
                                    </el-table-column>
                                </el-table>
                                <div class="pagination">
                                    <el-pagination background layout="prev, pager, next" :total="paginationForm.total"
                                        :current-page="paginationForm.pageNum" :page-size="paginationForm.pageSize"
                                        @current-change="currentChange" small>
                                    </el-pagination>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelChoose">取 消</el-button>
                    <el-button class="button" type="primary" @click="confirmGuest">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import commonPage from '@/components/public/pagination.vue'
import simplePage from '@/components/public/simple-page.vue'
import { Warning } from '@element-plus/icons-vue'
import { meetingListOpen, meetingListInProgress, meetingListClose, addMeeting, editMeeting, delMeeting, availableHostList, meetingDetail, userList, queryOrg, queryUser, getChildAll } from '@/api/api'
import moment from 'moment'
import { handleCopy } from '@/utils/utils'
import { MEETING_ADMIN_ID } from '@/utils/constants'
export default {
    components: {
        commonPage
        // simplePage
    },
    data() {
        var dateRangeValid = (rule, value, callback) => {
            if (!value) {
                callback(new Error('必填项不能为空'))
            } else if (value && (value[1] <= value[0])) {
                callback(new Error('结束时间需大于开始时间'))
            } else {
                callback()
            }
        }
        return {
            moment: moment,
            hostList: [],
            tableData: [],
            options: [],
            pageObj: {
                page: 1,
                size: 10,
                total: 1000
            },
            createDialog: {
                title: '创建会议',
                show: false,
                // 是否编辑状态
                isEdit: false
            },
            createForm: {
                subject: '',
                userId: '',
                dateRange: null,
                room: false,
                guest: ''
            },
            createRules: {
                subject: [
                    { required: true, message: '必填项不能为空', trigger: 'blur' }
                ],
                userId: [
                    { required: true, message: '必填项不能为空', trigger: 'blur' }
                ],
                dateRange: [
                    { required: true, validator: dateRangeValid, trigger: 'blur' }
                ]
            },
            searchForm: {
                userId: '',
                meetingStatus: 1
            },
            submitLoading: false,
            listLoading: false,
            pageLoading: false,
            tabList: [
                { id: 1, name: '即将召开' },
                { id: 2, name: '进行中' },
                { id: 3, name: '已结束的会议' }
            ],
            props: {
                label: 'name',
                children: 'children',
                isLeaf: 'isLeaf'
            },
            cacheData: [],
            treeId: 0,
            guestDialog: {
                show: false
            },
            treeLoading: false,
            Warning,
            activeName: 'first',
            depForm: {
                name: '',
                orgCode: ''
            },//部门表单
            persForm: {
                name: '',
                userId: ''
            },//人员表单
            tableData11: [],
            paginationForm: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            orgCode: '',//查询保存code
            tableLoading: true,
            userList: [],//人员列表(一个表格)
            userListAll: [],//人员列表(所有表格)
            userIdAll: [],//人员id列表(所有表格)
            orgList: [],//机构列表
            tableStatus: true,
            showStatus: 1,//判断请选择嘉宾还是修改嘉宾
            treeStatus: true,
            treeData: [],
            treeProps: {
                children: 'childNodes',
                label: 'orgFullName'
            },
            destroyStatus: true,
            guestData: [],//嘉宾列表显示
            guestName: '',
            userIdAll1: [],//人员id列表(所有表格编辑)
        }
    },
    created() {
        this.getHostList()
    },
    methods: {
        // 万物基础，主持人列表
        getHostList() {
            this.pageLoading = true
            availableHostList().then(res => {
                if (res.code === 0) {
                    this.hostList = res.data
                    this.searchForm.userId = res.data[0].userId
                    this.getOpenMeetingList()
                }
                this.pageLoading = false
            }).catch(() => {
                this.pageLoading = false
            })
        },
        loadTree(node, resolve) {
            if (node.isLeaf || node.data.type == 'user') return resolve([])
            var id = null
            // 使用 data.id 而不是 data.pid
            if (node.data && node.data.id) {
                id = node.data.id
            }
            this.treeLoading = true
            userList({ id }).then(res => {
                if (res.code === 0) {
                    res.data && res.data.map((item, index) => {
                        if (item.type == 'user') {
                            item.isLeaf = true
                        }
                    })
                    // console.log(this.orgList);
                    // console.log(res.data);
                    if (this.createDialog.title === '编辑会议') {
                        res.data.forEach(item => {
                            this.orgList.forEach(i => {
                                if (item.id === i.id) {
                                    item.hasChild = i.hasChild
                                }
                            })
                        })
                    }
                    resolve(res.data)
                }
                this.treeLoading = false
            }).catch(() => {
                this.treeLoading = false
            })
        },
        loadTree1(node, resolve) {
            if (node.isLeaf || node.data.type == 'user') return resolve([])
            var id = null
            // 使用 data.id 而不是 data.pid
            if (node.data && node.data.id) {
                id = node.data.id
            }
            this.treeLoading = true
            userList({ id }).then(res => {
                if (res.code === 0) {
                    res.data && res.data.map((item, index) => {
                        if (item.type == 'user') {
                            item.isLeaf = true
                        }
                    })
                    console.log(this.orgCode);
                    if (!this.orgCode) {
                        this.orgCode = res.data[0].id
                        this.queryUser()
                    }
                    resolve(res.data)
                }
                this.treeLoading = false
            }).catch(() => {
                this.treeLoading = false
            })
        },
        handleTreeCheckChange(node, data, nodelist) {
            // console.log('node', node)
            // console.log('data', node)
            // console.log('nodelist', nodelist)
            // // console.log(this.createForm.guest)
            // this.$refs.selectTree.setCheckedKeys([val.value])
        },
        removeTreeTag(ids) {
            var array = this.createForm.guest
            for (let index = 0; index < array.length; index++) {
                const element = array[index]
                if (element === ids) {
                    this.createForm.guest.splice(index, 1)
                    break
                }
            }
        },
        handleNodeClick(node) {
            // console.log(node)
        },
        chooseGuest1() {
            this.guestDialog.show = true
            this.destroyStatus = true
            this.showStatus = 2
            const timeInterval = setInterval(() => {
                if (this.$refs.selectTree) {
                    if (this.createForm.guest && this.createForm.guest.length > 0) {
                        this.$refs.selectTree.setCheckedKeys(this.createForm.guest)
                    } else {
                        // this.$refs.selectTree.setCheckedKeys([])
                    }
                    clearInterval(timeInterval)
                }
                if (this.$refs.selectTree1) {
                    if (this.createForm.guest && this.createForm.guest.length > 0) {
                        this.$refs.selectTree1.setCheckedKeys(this.createForm.guest)
                    } else {
                        // this.$refs.selectTree.setCheckedKeys([])
                    }
                    clearInterval(timeInterval)
                }
            }, 100)
        },
        chooseGuest2() {
            this.guestDialog.show = true
            this.destroyStatus = true
            this.showStatus = 1
            const timeInterval = setInterval(() => {
                if (this.$refs.selectTree) {
                    if (this.createForm.guest && this.createForm.guest.length > 0) {
                        this.$refs.selectTree.setCheckedKeys(this.createForm.guest)
                    } else {
                        // this.$refs.selectTree.setCheckedKeys([])
                    }
                    clearInterval(timeInterval)
                }
            }, 100)
        },
        cancelChoose() {
            this.persForm.name = ''
            this.persForm.userId = ''
            this.activeName = 'first'
            this.orgCode = ''
            if (this.treeStatus) {
                this.$refs.selectTree.setCheckedKeys([])
            }
            if (!this.treeStatus) {
                this.$refs.selectTree1.setCheckedKeys([])
            }
            if (this.showStatus === 1) {
                this.depForm.name = ''
                this.depForm.orgCode = ''
                this.userListAll = []
                this.userIdAll = []
                this.$refs.tableRef.clearSelection()//清空表格选择
            }
            // this.depForm.name = ''
            //     this.depForm.orgCode = ''
            // this.treeStatus = true
            this.guestDialog.show = false

        },
        confirmGuest() {
            if (this.treeStatus) {
                this.createForm.guest = this.$refs.selectTree.getCheckedKeys()
                this.orgList = this.$refs.selectTree.getCheckedNodes()
                this.$refs.selectTree.setCheckedKeys([])
            } else {
                this.createForm.guest = this.$refs.selectTree1.getCheckedKeys()
                this.orgList = this.$refs.selectTree1.getCheckedNodes()
                this.$refs.selectTree1.setCheckedKeys([])
            }
            //给嘉宾列表赋值
            let newOrg = []
            newOrg = this.orgList.map(item => {
                let i = item.hasChild ? '(包含子级)' : '(不包含子级)'
                return {
                    name: item.name + i
                }
            })
            let newUser = []
            this.userListAll.forEach(item => {
                item.arr.forEach(i => {
                    newUser.push({ name: i.guestName })
                });
            })
            let newArr = newOrg.concat(newUser)
            const map = new Map();
            const newArr1 = newArr.filter(v => !map.has(v.name) && map.set(v.name, v));
            newArr = newArr1
            this.guestData = newArr
            let newGuestData = []
            this.guestData.forEach(item => {
                newGuestData.push(item.name)
            })
            this.guestName = newGuestData.join(' ;');
            // console.log(this.orgList);
            // console.log(this.guestData);
            // this.depForm.name = ''
            // this.depForm.orgCode = ''
            this.persForm.name = ''
            this.persForm.userId = ''
            this.activeName = 'first'
            this.orgCode = ''
            // this.treeStatus = true
            this.guestDialog.show = false
        },
        // handleTabClick () {},
        handleCurrentChange(val) {
            this.pageObj.page = val
            this.judgeListRequest()
        },
        handleSizeChange(val) {
            this.pageObj.size = val
            this.pageObj.page = 1
            this.judgeListRequest()
        },
        handleNextClick() { },
        handleCreate() {
            this.createDialog.title = '创建会议'
            this.createForm.isEdit = false
            this.createDialog.show = true
        },
        handleEdit(item) {
            const loading = this.$loading({
                lock: true,
                text: '加载中...',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.createDialog.title = '编辑会议'
            this.createForm.isEdit = true
            const params = {
                meetingId: item.meetingId,
                // userId: this.searchForm.userId
                userId: MEETING_ADMIN_ID
            }
            meetingDetail(params).then(res => {
                if (res.code === 0) {
                    const response = res.data
                    this.createDialog.show = true
                    this.createForm.meetingId = response.meetingId
                    this.createForm.subject = response.subject
                    this.createForm.userId = ''
                    this.createForm.dateRange = [Number(response.startTime) * 1000, Number(response.endTime) * 1000]
                    this.createForm.room = (response.settings && response.settings.autoInWaitingRoom) || false
                    this.createForm.guest = response.newGuests
                    if (response.currentHosts && response.currentHosts.length > 0) {
                        this.createForm.userId = response.currentHosts[0].userId
                    }
                    if (response.hosts && response.hosts.length > 0) {
                        this.createForm.userId = response.hosts[0].userId
                    }
                    this.orgList = response.orgList
                    this.userIdAll = response.newGuests.map(item => {
                        return item.id
                    })
                    this.userIdAll1 = response.newGuests.map(item => {
                        return item.id
                    })
                    this.createForm.guest = response.orgList.map(item => {
                        return item.id
                    })
                    // console.log(response);
                    // console.log(this.userIdAll);
                    // console.log(this.createForm.guest);
                    let newOrg = []
                    newOrg = this.orgList.map(item => {
                        let i = item.hasChild ? '(包含子级)' : '(不包含子级)'
                        return {
                            name: item.name + i
                        }
                    })
                    let newUser = []
                    response.newGuests.forEach(item => {
                        newUser.push({ name: item.name })
                    })
                    let newArr = newOrg.concat(newUser)
                    const map = new Map();
                    const newArr1 = newArr.filter(v => !map.has(v.name) && map.set(v.name, v));
                    newArr = newArr1
                    this.guestData = newArr
                    let newGuestData = []
                    this.guestData.forEach(item => {
                        newGuestData.push(item.name)
                    })
                    this.guestName = newGuestData.join(' ;');
                }
                loading.close()
            }).catch(() => {
                loading.close()
            })
        },
        submitCreateForm() {
            this.submitLoading = true
            this.$refs.createForm.validate((valid) => {
                if (valid) {
                    // 会议结束时间不能小于当前时间
                    const now = Date.now()
                    if (this.createForm.dateRange[1] < now) {
                        this.$message.error('结束时间不能小于当前时间')
                        this.submitLoading = false
                        return
                    }
                    //编辑数据
                    let newIdAll = this.userIdAll.map(item => {
                        return {
                            id: item,
                            hasChild: false
                        }
                    })
                    let newOrgList = this.orgList.map(item => {
                        return {
                            id: item.id,
                            hasChild: item.hasChild,
                        }
                    })
                    let newGuests = newIdAll
                    const map = new Map();
                    const newArr = newGuests.filter(v => !map.has(v.id) && map.set(v.id, v));
                    newGuests = newArr
                    const data = {
                        subject: this.createForm.subject,
                        userId: MEETING_ADMIN_ID,
                        hosts: [
                            {
                                userId: this.createForm.userId // 主持人ID
                            }
                        ],
                        startTime: this.createForm.dateRange[0] / 1000,
                        endTime: this.createForm.dateRange[1] / 1000,
                        settings: {
                            autoInWaitingRoom: this.createForm.room
                        },
                        // newGuests: this.createForm.guest || []
                        newGuests: newGuests || [],
                        orgList: newOrgList || []
                        // guests: [
                        //   {
                        //     area: 86, // 暂时写死
                        //     phoneNumber: 18081031257 // 嘉宾电话号码
                        //   }
                        // ]
                    }
                    let requestMethod
                    if (this.createForm.isEdit) {
                        data.meetingId = this.createForm.meetingId
                        requestMethod = editMeeting
                    } else {
                        requestMethod = addMeeting
                    }
                    requestMethod(data).then(res => {
                        if (res.code === 0) {
                            if (this.createForm.isEdit) {
                                this.$message.success('编辑成功，如果数据未及时更新，请点击刷新按钮')
                            } else {
                                this.pageObj.page = 1
                                this.$message.success('创建成功，如果数据未及时更新，请点击刷新按钮')
                            }
                            this.cancelCreate()
                            this.judgeListRequest()
                        }
                        this.submitLoading = false
                        // location.reload();
                        this.destroyStatus = false
                    }).catch(() => {
                        this.submitLoading = false
                    })
                } else {
                    this.submitLoading = false
                }
            })
        },
        resetForm() {
            this.createForm = {
                subject: '',
                userId: '',
                dateRange: null,
                room: false,
                guest: ''
            }
            this.guestData = []
            this.guestName = ''
            this.depForm.name = ''
            this.depForm.orgCode = ''
            this.treeStatus = true
            this.orgList.forEach(item => {
                item.hasChild = false
            })
            this.userList = []
            this.userListAll = []
            this.userIdAll = []
            // this.orgList = []
            this.$refs.createForm.clearValidate()
            if (this.$refs.tableRef) {
                this.$refs.tableRef.clearSelection()
            }
        },
        cancelCreate() {
            this.resetForm()
            this.createDialog.show = false
            this.destroyStatus = false
        },
        handleTabSwitch(item) {
            if (this.listLoading) {
                return
            }
            this.pageObj.page = 1
            this.searchForm.meetingStatus = item.id
            this.judgeListRequest()
        },
        handleDetail(item) {
            const routeData = this.$router.resolve({
                path: '/meetingDetail',
                // query: { id: item.meetingId, host: this.searchForm.userId }
                query: { id: item.meetingId, host: MEETING_ADMIN_ID }
            })
            window.open(routeData.href, '_blank')
        },
        getOpenMeetingList() {
            this.tableData = []
            this.listLoading = true
            const params = {
                page: this.pageObj.page,
                pageSize: this.pageObj.size
            }
            meetingListOpen(params).then(res => {
                if (res.code === 0) {
                    this.tableData = res.data.meetingInfoList
                    this.pageObj.total = res.data.totalCount
                }
                this.listLoading = false
            }).catch(() => {
                this.listLoading = false
            })
        },
        getOngoingMeetingList() {
            this.tableData = []
            this.listLoading = true
            const params = {
                page: this.pageObj.page,
                pageSize: this.pageObj.size
            }
            meetingListInProgress(params).then(res => {
                if (res.code === 0) {
                    this.tableData = res.data.meetingInfoList
                    this.pageObj.total = res.data.totalCount
                }
                this.listLoading = false
            }).catch(() => {
                this.listLoading = false
            })
        },
        getClosedMeetingList() {
            this.tableData = []
            this.listLoading = true
            const data = {
                page: this.pageObj.page,
                pageSize: this.pageObj.size
            }
            meetingListClose(data).then(res => {
                if (res.code === 0) {
                    this.tableData = res.data.meetingInfoList
                    this.pageObj.total = res.data.totalCount
                }
                this.listLoading = false
            }).catch(res => {
                this.listLoading = false
            })
        },
        judgeListRequest() {
            if (this.searchForm.meetingStatus == 1) {
                this.getOpenMeetingList()
            } else if (this.searchForm.meetingStatus == 2) {
                this.getOngoingMeetingList()
            } else {
                this.getClosedMeetingList()
            }
        },
        userChange(val) {
            this.searchForm.userId = val
            this.pageObj.page = 1
            this.judgeListRequest()
        },
        handleDel(item) {
            const data = {
                meetingId: item.meetingId,
                // userId: this.searchForm.userId
                userId: MEETING_ADMIN_ID
            }
            this.$confirm('此操作将取消会议, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    delMeeting(data).then(res => {
                        if (res.code === 0) {
                            this.$message.success('取消成功，如果数据为及时更新，请点击刷新按钮')
                            this.judgeListRequest()
                        }
                    })
                })
                .catch(() => {
                })
        },
        handleCopy(text) {
            handleCopy(text)
        },
        handleOpen(url) {
            if (!url) return
            window.open(url)
        },
        async changeTree(data) {
            if (!data.isEnd) {
                this.$message({
                    message: '请先选中该节点',
                    type: 'warning'
                });
                data.hasChild = false
                return
            }
            if (!data.hasChild) {
                //取消包含本级
                this.$refs.selectTree.getNode(data).expanded = true
            }
            if (data.hasChild) {
                //选中包含本级
                const res = await getChildAll({ id: data.id })
                res.data.forEach((item) => {
                    item.hasChild = false
                    this.$nextTick(() => {
                        this.$refs.selectTree.setChecked(item.id, false, true)
                    })
                })
                let nodeArr = this.$refs.selectTree.getCheckedNodes();
                nodeArr.forEach(item => {
                    res.data.forEach((i) => {
                        if (item.id === i.id) {
                            item.hasChild = false
                        }
                    })
                })
                this.$refs.selectTree.getNode(data).expanded = false
            }
        },
        async changeTree1(data) {
            if (!data.end) {
                this.$message({
                    message: '请先选中该节点',
                    type: 'warning'
                });
                data.hasChild = false
                return
            }
            if (!data.hasChild) {
                //取消包含本级
                this.$refs.selectTree1.getNode(data).expanded = true
            }
            if (data.hasChild) {
                //选中包含本级
                const res = await getChildAll({ id: data.orgCode })
                res.data.forEach((item) => {
                    item.hasChild = false
                    this.$nextTick(() => {
                        this.$refs.selectTree1.setChecked(item.id, false, true)
                    })
                })
                let nodeArr = this.$refs.selectTree1.getCheckedNodes();
                nodeArr.forEach(item => {
                    res.data.forEach((i) => {
                        if (item.orgCode === i.id) {
                            item.hasChild = false
                        }
                    })
                })
                this.$refs.selectTree1.getNode(data).expanded = false
            }
        },
        //部门查询
        async queryDep() {
            if (!this.depForm.name && !this.depForm.orgCode) {
                this.treeStatus = true
                return
            }
            const res = await queryOrg(this.depForm)
            if (res.data.length > 0) {
                this.treeStatus = false
                this.treeData = res.data
            } else {
                this.treeStatus = true
            }
        },
        //人员查询
        async queryUser() {
            let params = {
                ...this.persForm,
                pageNum: this.paginationForm.pageNum,
                pageSize: this.paginationForm.pageSize,
                orgCode: this.orgCode
            }
            const res = await queryUser(params)
            if (res.code === 0) {
                this.paginationForm.total = res.total
                this.tableLoading = false
                this.tableData11 = res.rows
                if (this.createDialog.title === '创建会议') {
                    let rowArr = []
                    res.rows.forEach(item => {
                        this.userIdAll.forEach(i => {
                            if (item.userId === i) {
                                rowArr.push(item)
                            }
                        })
                    });
                    const map = new Map();
                    const newArr = rowArr.filter(v => !map.has(v.userId) && map.set(v.userId, v));
                    rowArr = newArr
                    this.$nextTick(() => {
                        rowArr.forEach(item => {
                            this.$refs.tableRef.toggleRowSelection(item, true)
                        }
                        )
                    })
                } else {
                    let rowArr = []
                    res.rows.forEach(item => {
                        this.userIdAll1.forEach(i => {
                            if (item.userId === i) {
                                rowArr.push(item)
                            }
                        })
                    });
                    const map = new Map();
                    const newArr = rowArr.filter(v => !map.has(v.userId) && map.set(v.userId, v));
                    rowArr = newArr
                    this.$nextTick(() => {
                        rowArr.forEach(item => {
                            this.$refs.tableRef.toggleRowSelection(item, true)
                        }
                        )
                    })
                }
            }
        },
        async userHandleNodeClick(node) {
            this.tableStatus = false
            if (node.id !== this.orgCode) {
                this.orgCode = node.id
                this.queryUser()
            }
        },
        //分页
        currentChange(val) {
            this.paginationForm.pageNum = val
            this.queryUser()
        },
        //表格复选框
        handleSelectionChange(val) {
            this.tableStatus = true
            this.userList = val
            let newUserList = JSON.parse(JSON.stringify(this.userList));
            let params = {
                id: this.orgCode,
                arr: newUserList
            }
            let status = false
            for (let i = 0; i < this.userListAll.length; i++) {
                if (this.userListAll[i].id === this.orgCode) {
                    status = !status
                    break
                }
            }
            if (status && this.tableStatus) {
                //先删除在添加
                let newArr = this.userListAll.filter((item) => {
                    return item.id !== this.orgCode
                })
                this.userListAll = newArr
                this.userListAll.push(params)
            }
            if (!status && this.tableStatus) {
                this.userListAll.push(params)
            }

            // const map = new Map();
            // const newArr = this.userListAll.filter(v => !map.has(v.id) && map.set(v.id, v));
            // this.userListAll = newArr
            let newArr = []
            this.userListAll.forEach(item => {
                item.arr.forEach(i => {
                    newArr.push(i.userId)
                });
            })
            this.userIdAll = newArr
            // console.log(this.userIdAll, '22');
        },
        //tree组件复选框事件
        async checkClick(node1, node2) {
            const contains = node2.checkedKeys.includes(node1.id);
            if (contains) {
                //表示选中
                // this.$refs.selectTree.getNode(node1).expanded = false
                node1.hasChild = true
                node1.isEnd = true //表示节点是否选中
                const res = await getChildAll({ id: node1.id })
                res.data.forEach((item) => {
                    item.hasChild = false
                    this.$nextTick(() => {
                        this.$refs.selectTree.setChecked(item.id, false, true)
                    })
                })
                node2.checkedNodes.forEach(item => {
                    res.data.forEach((i) => {
                        if (item.id === i.id) {
                            item.hasChild = false
                        }
                    })
                })
                this.$refs.selectTree.getNode(node1).expanded = false
            } else {
                // 表示取消
                node1.hasChild = false
                node1.isEnd = false
                this.$refs.selectTree.getNode(node1).expanded = true
            }
        },
        async checkClick1(node1, node2) {
            const contains = node2.checkedKeys.includes(node1.orgCode);
            if (contains) {
                //表示选中
                // this.$refs.selectTree.getNode(node1).expanded = false
                node1.hasChild = true
                node1.end = true //表示节点是否选中
                const res = await getChildAll({ id: node1.orgCode })
                res.data.forEach((item) => {
                    item.hasChild = false
                    this.$nextTick(() => {
                        this.$refs.selectTree1.setChecked(item.id, false, true)
                    })
                })
                node2.checkedNodes.forEach(item => {
                    res.data.forEach((i) => {
                        if (item.orgCode === i.id) {
                            item.hasChild = false
                        }
                    })
                })
                this.$refs.selectTree1.getNode(node1).expanded = false
            } else {
                // 表示取消 
                node1.hasChild = false
                node1.end = false
                this.$refs.selectTree1.getNode(node1).expanded = true
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.container {
    margin: 0 auto;
    width: 1200px;
    background: white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    padding: 20px;
    margin-top: 20px;
}

.header {
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 20px;

    .icon {
        width: 6px;
        height: 30px;
        background: $colorGreen;
        margin-right: 10px;
    }

    .title {
        line-height: 40px;
        font-size: 18px;
        font-weight: bold;
    }

    .search {
        margin-left: auto;
    }
}

.condition {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.create-dialog {
    .main {
        display: flex;
        justify-content: center;
    }
}

.tabs {
    display: flex;
    height: 40px;
    color: black;
    font-size: 18px;

    .tab-item {
        height: 40px;
        line-height: 40px;
        margin-right: 40px;
        letter-spacing: 1px;
        cursor: pointer;

        &:last-child {
            margin-right: 0px;
        }

        &:hover {
            color: $colorGreen;
        }
    }

    .active {
        color: $colorGreen;
        font-weight: 500;
        border-bottom: 3px solid $colorGreen;
    }

    .tab-disabled {
        cursor: default;
        color: $subTitleColor;

        &:hover {
            color: $subTitleColor !important;
        }
    }
}

.link-url {
    cursor: pointer;

    &:hover {
        color: $colorBlue;
        text-decoration: underline;
    }
}

.formClass {
    display: flex;
}

.treeClass {
    border: 1px solid #ccc;
    width: 99%;
    height: 31vh;
    overflow-y: auto
}

.boxClass {
    border: 1px solid #ccc;
    width: 99%;
    height: 38vh;
    // overflow-y: auto;
    display: flex;
    justify-content: space-around;

    .tree {
        width: 30%;
        height: 100%;
        overflow-y: auto;
        // border: 1px solid red;
    }

    .table {
        width: 68%;
        height: 100%;
        // border: 1px solid red;

        .tableList {
            width: 100%;
            height: 85%;
            // border: 1px solid red;
            overflow-y: auto;
        }

        .pagination {
            width: 100%;
            // border: 1px solid red;
            margin-top: 10px;
        }
    }
}

.page {
    :deep(.el-dialog__body) {
        padding-bottom: 10px;
        padding-top: 10px;
    }
}

.page {
    :deep(.el-checkbox__input) {
        margin-left: 3px;
    }
}

.boxItem {
    width: 100%;
    height: 70px;
    border: 1px solid #ccc;
    word-wrap: break-word;
    overflow-y: scroll;
    font-size: 14px;
}

.box11 {
    display: flex;
}

.spanBox {
    display: block;
    // margin-bottom: 10px;
    //    white-space: pre-wrap;
}
</style>
